import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const RadioEdit = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;

    var [radioRecord, setRadioRecord] = useState(props.radioRecord ? props.radioRecord : null);
    var [locationX, setLocationX] = useState(radioRecord && radioRecord.locationMap ? radioRecord.locationMap.x : '');
    var [locationY, setLocationY] = useState(radioRecord && radioRecord.locationMap ? radioRecord.locationMap.y : '');
    var [locationZ, setLocationZ] = useState(radioRecord && radioRecord.locationMap ? radioRecord.locationMap.z : '');

    const handleClose = () => {
        onClose(null);
    };

    function handleSubmit(event) {
        event.preventDefault();
        onClose(radioRecord);
    }

    if (props.radioRecord && (!radioRecord || radioRecord.radioId !== props.radioRecord.radioId)) {
        radioRecord = JSON.parse(JSON.stringify(props.radioRecord));
        setRadioRecord(radioRecord);
        if (radioRecord.locationMap) {
            setLocationX(radioRecord.locationMap.x);
            setLocationY(radioRecord.locationMap.y);
            setLocationZ(radioRecord.locationMap.z);
        }
    }

    function setLocationXX(v) {
        if (!radioRecord.locationMap)
            radioRecord.locationMap = { x : v};
        else
            radioRecord.locationMap.x = v;
        setLocationX(v);
    }

    function setLocationYX(v) {
        if (!radioRecord.locationMap)
            radioRecord.locationMap = { y : v};
        else
            radioRecord.locationMap.y = v;
        setLocationY(v);
    }

    function setLocationZX(v) {
        if (!radioRecord.locationMap)
            radioRecord.locationMap = { z : v};
        else
            radioRecord.locationMap.z = v;
        setLocationZ(v);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Edit Radio -- {radioRecord ? radioRecord.radioId : ''}
                    </Typography>
                    {radioRecord ? 
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="node"
                            label="Node"
                            value={radioRecord.node}
                            name="node"
                            disabled
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="radioMac"
                            label="Radio ID"
                            value={radioRecord.radioMac}
                            id="radioType"
                            disabled
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationX"
                            label="X (meters)"
                            value={locationX}
                            onInput={e=>setLocationXX(e.target.value)}
                            id="locationX"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationY"
                            label="Y (meters)"
                            value={locationY}
                            onInput={e=>setLocationYX(e.target.value)}
                            id="locationY"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationZ"
                            label="Z (meters)"
                            value={locationZ}
                            onInput={e=>setLocationZX(e.target.value)}
                            id="locationZ"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Save
                        </Button>
                    </form>
                    : ''}
                </div>
            </Container>
        </Dialog>
    );
}

RadioEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default RadioEdit;