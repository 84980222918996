import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const TestEdit = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;

    var [testRecord, setTestRecord] = useState(props.testRecord ? props.testRecord : null);
    var [name, setName] = useState(testRecord ? testRecord.name : '');
    var [description, setDescription] = useState(testRecord ? testRecord.description : '');
/*
    useEffect(() => {
            console.log('simple dialog mounting');
            return () => {
                console.log('simple dialog un-mounting');
            }
        }, []);
*/
    const handleClose = () => {
        onClose(null);
    };

    function handleSubmit(event) {
        event.preventDefault();
        onClose(testRecord);
    }

    if (props.testRecord && (!testRecord || testRecord.testId !== props.testRecord.testId)) {
        testRecord = JSON.parse(JSON.stringify(props.testRecord));
        setTestRecord(testRecord);
        setName(testRecord.name);
        setDescription(testRecord.description);
 //       console.log( 'Setting Name:', name, 'Description: ', description); 
 //   } else {
 //       console.log( 'Setting Name:', name, 'Description: ', description); 
    }
    /*
    if (testRecord)
        console.log('simple ' + testRecord.testId);
    else 
        console.log('simple null testRecord');
        */

    function setNameX(v) {
        testRecord.name = v;
        setName(v);
    }
    function setDescriptionX(v) {
        testRecord.description = v;
        setDescription(v);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Edit Test -- {testRecord ? testRecord.testId : ''}
                    </Typography>
                    {testRecord ? 
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            value={name}
                            onInput={e=>setNameX(e.target.value)}
                            name="name"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="description"
                            label="Description"
                            value={description}
                            onInput={e=>setDescriptionX(e.target.value)}
                            id="description"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Save
                        </Button>
                    </form>
                    : ''}
                </div>
            </Container>
        </Dialog>
    );
}

TestEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default TestEdit;
