import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useState } from 'react';
import { useEffect } from 'react';
import request from "superagent";
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const AssetEdit = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;

    var [assetRecord, setAssetRecord] = useState(props.assetRecord ? props.assetRecord : null);
    var [tag, setTag] = useState(assetRecord ? assetRecord.tag : '');
    var [assetType, setAssetType] = useState(assetRecord ? assetRecord.assetType : '');
    var [platform, setPlatform] = useState(assetRecord ? assetRecord.platform : '');
    var [note, setNote] = useState(assetRecord ? assetRecord.note : '');
    var [locationX, setLocationX] = useState(assetRecord && assetRecord.locationMap ? assetRecord.locationMap.x : '');
    var [locationY, setLocationY] = useState(assetRecord && assetRecord.locationMap ? assetRecord.locationMap.y : '');
    var [locationZ, setLocationZ] = useState(assetRecord && assetRecord.locationMap ? assetRecord.locationMap.z : '');
    var [testId, setTestId] = useState(assetRecord ? assetRecord.testId : '');
    var [tests, setTests] = useState([]);

    useEffect(() => {
        request.get(props.apiUrl + "tests")
            .then(response => {
                setTests(response.body.tests);
            }
        );

//        return () => {
//            console.log('simple dialog un-mounting');
//        }
    }, [props.apiUrl]);

    const handleClose = () => {
        onClose(null);
    };

    function handleSubmit(event) {
        event.preventDefault();
        onClose(assetRecord);
    }

    if (props.assetRecord && (!assetRecord || assetRecord.assetId !== props.assetRecord.assetId)) {
        assetRecord = JSON.parse(JSON.stringify(props.assetRecord));
        setAssetRecord(assetRecord);
        setTag(assetRecord.tag);
        setAssetType(assetRecord.assetType);
        setPlatform(assetRecord.platform);
        setNote(assetRecord.note);
        if (assetRecord.locationMap) {
            setLocationX(assetRecord.locationMap.x);
            setLocationY(assetRecord.locationMap.y);
            setLocationZ(assetRecord.locationMap.z);
        }
        setTestId(assetRecord.testId);
    }

    function setLocationXX(v) {
        if (!assetRecord.locationMap)
            assetRecord.locationMap = { x: v };
        else
            assetRecord.locationMap.x = v;
        setLocationX(v);
    }

    function setLocationYX(v) {
        if (!assetRecord.locationMap)
            assetRecord.locationMap = { y: v };
        else
            assetRecord.locationMap.y = v;
        setLocationY(v);
    }

    function setLocationZX(v) {
        if (!assetRecord.locationMap)
            assetRecord.locationMap = { z: v };
        else
            assetRecord.locationMap.z = v;
        setLocationZ(v);
    }

    function setTagX(v) {
        assetRecord.tag = v;
        setTag(v);
    }
    function setAssetTypeX(v) {
        assetRecord.assetType = v;
        setAssetType(v);
    }
    function setPlatformX(v) {
        assetRecord.platform = v;
        setPlatform(v);
    }
    function setNoteX(v) {
        assetRecord.note = v;
        setNote(v);
    }
    
    function testChange (e, test) {
        assetRecord.testId = test.testId;
        setTestId(test.testId);
    }

    const test = tests.find(t => t.testId === testId);

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Edit Asset -- {assetRecord ? assetRecord.assetId : ''}
                    </Typography>
                    {assetRecord ? 
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="assetUuid"
                            label="Asset ID"
                            value={assetRecord.assetUuid}
                            name="assetUuid"
                            disabled
                        />
                        {/*
                        <TextField
                            margin="normal"
                            fullWidth
                            name="serialNumber"
                            label="Serial #"
                            value={assetRecord.serialNumber}
                            id="serialNumber"
                            disabled
                        />
                        */}
                        <TextField
                            margin="normal"
                            fullWidth
                            name="batteryPercent"
                            label="Battery %"
                            value={assetRecord.batteryPercent}
                            id="batteryPercent"
                            disabled
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="lastMovement"
                            label="Last Movement"
                            value={assetRecord.lastMovement}
                            id="lastMovement"
                            disabled
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="lastReport"
                            label="Last Report"
                            value={assetRecord.lastReport}
                            id="lastReport"
                            disabled
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="tag"
                            label="Tag"
                            value={tag}
                            onInput={e=>setTagX(e.target.value)}
                            name="tag"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="assetType"
                            label="Asset Type"
                            value={assetType}
                            onInput={e=>setAssetTypeX(e.target.value)}
                            id="assetType"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="platform"
                            label="Platform"
                            value={platform}
                            onInput={e=>setPlatformX(e.target.value)}
                            id="platform"
                            />
                        <Autocomplete
                            id="test-select"
                            options={tests}
                            getOptionLabel={(option) => option.name}
//                            getOptionSelected=
                            defaultValue={test}
                            style={{paddingTop:'8px'}}
                            onChange={testChange}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    label="Current Test" 
                                    variant="outlined" 
                                    fullWidth
                                    />}
                            />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="note"
                            label="Note"
                            value={note}
                            onInput={e=>setNoteX(e.target.value)}
                            id="note"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationX"
                            label="X (meters)"
                            value={locationX}
                            onInput={e=>setLocationXX(e.target.value)}
                            id="locationX"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationY"
                            label="Y (meters)"
                            value={locationY}
                            onInput={e=>setLocationYX(e.target.value)}
                            id="locationY"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="locationZ"
                            label="Z (meters)"
                            value={locationZ}
                            onInput={e=>setLocationZX(e.target.value)}
                            id="locationZ"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Save
                        </Button>
                    </form>
                    : ''}
                </div>
            </Container>
        </Dialog>
    );
}

AssetEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default AssetEdit;