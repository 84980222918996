import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import MainPage from "./MainPage";

const apiUrl = 'https://wherehouse-api.locatorx.com/api/';
//const apiUrl = 'http://wherehouse:8080/api/';

export default class App extends React.Component {
    render() {
        const NoAuthRoute = ({ component: Component, ...rest }) => (
            <Route
                {...rest}
                render={props => <Component {...props} apiUrl={apiUrl} />}
            />
        );

        return (
            <Router>
                <div>
                    <NoAuthRoute exact path="/" component={MainPage} />
                </div>
            </Router>
        );
    }
}
