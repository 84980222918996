import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Assets from './Assets';
import Radios from './Radios';
import Logs from './Logs';
import Tests from './Tests';
import AssetRadios from './AssetRadios';
import Configuration from './Configuration';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

export default function MainPage(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.root}>
            <h1>LocatorX Automated Inventory</h1>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Assets" />
                <Tab label="Radios" />
                <Tab label="Logs" />
                <Tab label="Asset Radios" />
                <Tab label="Tests" />
                <Tab label="Configuration" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Assets {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Radios {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Logs {...props} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AssetRadios {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Tests {...props} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Configuration {...props} />
            </TabPanel>
        </Paper>
    );
}